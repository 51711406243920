import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"


const blogCat = ({ data, pageContext: { currentPage, numPages } }) => {

    const { allMarkdownRemark: { blogNodes } } = data
    const blogCards = blogNodes.map((b, i) => (

        <div className="col-lg-4 col-md-6" key={i}>
            <div className="single-blog-post bg-fffbf5">
                <div className="post-image">
                    <Link to={"/blog" + b.fields.slug}>
                        <Img fluid={b.frontmatter.banner?.childImageSharp.fluid} alt="Thumbnail" />
                    </Link>
                </div>



                <div className="post-content">
                    <ul className="post-meta d-flex justify-content-between align-items-center">
                        <li>
                            <div className="post-author d-flex align-items-center">
                                <span>{b.frontmatter.author}</span>
                            </div>
                        </li>
                        <li>
                            <i className='flaticon-stopwatch'></i> {b.timeToRead} mins
                        </li>
                        <li>
                            <i className='flaticon-calendar'></i> {b.frontmatter.date}
                        </li>
                    </ul>
                    <h3>
                        <Link to={"/blog" + b.fields.slug}>
                            {b.frontmatter.title}
                        </Link>
                    </h3>
                </div>
            </div>
        </div>
    ))



    const paginationBar = [...Array(numPages)].map((_, i) =>
        ++i === currentPage ?
            <span className="page-numbers current" aria-current="page">{i}</span> :
            <Link to={"/blogs/" + (i === 1 ? "" : i)} className="page-numbers">{i}</Link>
    )

    return (

        <Layout>
            <Navbar />

            <div className="blog-area ptb-100">
                <div className="container">
                    <div className="row">


                        {blogCards}



                        <div className="col-lg-12 col-md-12">
                            {/* Pagination */}
                            <div className="pagination-area text-center">



                                {/* <Link to="#" className="prev page-numbers">
                                <i className='bx bx-chevrons-left'></i>
                            </Link> */}




                                {paginationBar}


                                {/* <Link to="#" className="next page-numbers">
                                <i className='bx bx-chevrons-right'></i>
                            </Link> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}


export default blogCat;

export const pageQuery = graphql`
query CategorizedBlogPosts($formatString: String = "MMM D, Y", $locale: String = "de", $limit: Int = 1, $skip: Int = 0, $category : String) {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: $limit, skip: $skip, filter: { frontmatter: { tags: { in: [$category] } } }) {
      blogNodes: nodes {
        frontmatter {
          title
          description
          date(formatString: $formatString, locale: $locale)
          author
          banner {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        timeToRead
        fields {
          slug
        }
      }
      totalCount
    }
  }
`